<template>
    <div class="about-us">
        <div class="group-banner">
            <b-col
                offset-lg="2"
                lg="8"
                md="12"
                cols="12"
            >
                <b-img
                    fluid
                    :src="require('@/assets/images/home/group.png')"
                    alt="group-img"
                />
            </b-col>
        </div>
        <b-row class="m-0">
            <b-col
                class="about-text"
                offset-lg="2"
                lg="5"
                md="12"
                cols="12"
            >
                <span class="top-title">About COST CTRL</span>
                <h2>Give a boost to your business finances and optimize the process</h2>
                <p>Cost Ctrl is cloud-based invoice/receipt management solution, which helps retailers scan and process receipts and invoices. Key features include invoice management, payment processing, finance administration, custom branding, expense...</p>
                <b-button class="about-btn" variant="none">
                    Know more about us
                </b-button>
                <b-button class="about-btn-gradient">
                    Sign up for free
                    <feather-icon
                        icon="ArrowRightIcon"
                    />
                </b-button>
            </b-col>
            <b-col
                class="about-img pr-0 mr-0"
                lg="5"
                md="12"
                cols="12"
            >
                <slick
                    ref="slickAbout"
                    :options="slickOptionsAbout"
                >
                    <b-img
                        fluid
                        :src="require('@/assets/images/home/about-us.png')"
                        alt="about-img-1"
                    />
                    <b-img
                        fluid
                        :src="require('@/assets/images/home/about-us.png')"
                        alt="about-img-1"
                    />
                </slick>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import $ from "jquery";
import { BRow, BCol, BButton, BImg } from 'bootstrap-vue'
import Slick from 'vue-slick'
import 'slick-carousel/slick/slick.css'


export default {
    components: {
        BRow,
        BCol,
        BButton,
        BImg,
        Slick
    },
    data() {
        return {
            slickOptionsAbout: {
				dots: true,
				arrows: false,
				slidesToShow: 1,
				slidesToScroll: 1,
            },
        };
    },
}
</script>