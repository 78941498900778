<template>
  <div>
    <banner-home />
    <about-home />
    <step-home />
    <benefits-home />
    <contact-home />
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import bannerHome from './bannerHome.vue'
import aboutHome from './aboutHome.vue'
import stepHome from './stepHome.vue'
import benefitsHome from './benefitsHome.vue'
import contactHome from './contactHome.vue'


export default {
  components: {
    BRow,
    BCol,
    
    bannerHome,
    aboutHome,
    stepHome,
    benefitsHome,
    contactHome,
   
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-home.scss';
</style>
