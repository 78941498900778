<template>
    <div class="banner-home">
        <div class="banner-bg-left"></div>
        <div class="banner-bg-right-1"></div>
        <div class="banner-bg-right-2"></div>
        <b-col
            class="banner-text"
            offset-lg="2"
            lg="8"
            md="12"
            cols="12"
        >
            <slick
                ref="slickBanner"
                :options="slickOptionsBanner"
            >
                <div class="banner">
                    <h1>Control Your Costs to Maximize Profits</h1>
                    <p>Cost Ctrl finds profitability and productivity within your business. We use your data to transcend traditional financial reports and build models which show how your business really makes its money.</p>
                    <b-button class="banner-btn">
                        Sign up for the release and receive your first month free
                        <feather-icon
                            icon="ArrowRightIcon"
                        />
                    </b-button>
                </div>
                <div class="banner">
                    <h1>Control Your Costs to Maximize Profits</h1>
                    <p>Cost Ctrl finds profitability and productivity within your business. We use your data to transcend traditional financial reports and build models which show how your business really makes its money.</p>
                    <b-button class="banner-btn">
                        Sign up for the release and receive your first month free
                        <feather-icon
                            icon="ArrowRightIcon"
                        />
                    </b-button>
                </div>
            </slick>
            <div class="banner-arrow">
                <a href="javascript:void(0)" @click="handlePrevSlideBanner" class="arrow-prev">
                    <feather-icon
                        icon="ArrowLeftIcon"
                    />
                </a>
                <a href="javascript:void(0)" @click="handleNextSlideBanner" class="arrow-next">
                    <feather-icon
                        icon="ArrowRightIcon"
                    />
                </a>
            </div>
        </b-col>
    </div>
</template>

<script>
import $ from "jquery";
import { BRow, BCol, BButton, BImg } from 'bootstrap-vue'
import Slick from 'vue-slick'
import 'slick-carousel/slick/slick.css'


export default {
    components: {
        BRow,
        BCol,
        BButton,
        BImg,
        Slick
    },
    data() {
        return {
            slickOptionsBanner: {
				dots: true,
				arrows: false,
				slidesToShow: 1,
				slidesToScroll: 1,
                responsive: [
                    {
                    breakpoint: 992,
                        settings: {
                            arrows: true
                        }
                    },
                ]
            },
        };
    },
    methods: {
		handleNextSlideBanner() {
			this.$refs.slickBanner.next();
		},
		handlePrevSlideBanner() {
			this.$refs.slickBanner.prev();
		},
	}
}
</script>