<template>
    <div class="step-section">
        <b-row class="m-0 p-0">
            <b-col
                class="step-text"
                offset-lg="2"
                lg="8"
                md="10"
                cols="10"
            >
                <span class="top-title">Steps</span>
                <h3>Easy to follow step by step process</h3>
                <p>which will guide you through the building and maintenance of your cost and profitability models.</p>
            </b-col>
            <b-col class="step-arrow" lg="2" md="2" cols="2">
                <div>
                <a href="javascript:void(0)" @click="handlePrevSlideStep" class="arrow-prev">
                    <feather-icon
                        icon="ArrowLeftCircleIcon"
                    />
                </a>
                <a href="javascript:void(0)" @click="handleNextSlideStep" class="arrow-next">
                    <feather-icon
                        icon="ArrowRightCircleIcon"
                    />
                </a>
            </div>
            </b-col>
        </b-row>
        <b-row class="m-0 p-0">
            <b-col
                class="step-carousel"
                md="12"
            >
                <slick
                    ref="slickAbout"
                    :options="slickOptionsStep"
                >
                    <div class="step-img">
                        <b-img
                            fluid
                            :src="require('@/assets/images/home/step-1.png')"
                            alt="contact-icon"
                        />
                        <p>Create Models and Scenarios</p>
                        <span>Create Models and Scenarios all the way from the start or start running adapting from one of our Industry Templates and demo data sets.</span>
                    </div>
                    <div class="step-img">
                        <b-img
                            fluid
                            :src="require('@/assets/images/home/step-2.png')"
                            alt="contact-icon"
                        />
                        <p>Create Models and Scenarios</p>
                        <span>Create Models and Scenarios all the way from the start or start running adapting from one of our Industry Templates and demo data sets.</span>
                    </div>
                    <div class="step-img">
                        <b-img
                            fluid
                            :src="require('@/assets/images/home/step-3.png')"
                            alt="contact-icon"
                        />
                        <p>Create Models and Scenarios</p>
                        <span>Create Models and Scenarios all the way from the start or start running adapting from one of our Industry Templates and demo data sets.</span>
                    </div>
                    <div class="step-img">
                        <b-img
                            fluid
                            :src="require('@/assets/images/home/step-4.png')"
                            alt="contact-icon"
                        />
                        <p>Create Models and Scenarios</p>
                        <span>Create Models and Scenarios all the way from the start or start running adapting from one of our Industry Templates and demo data sets.</span>
                    </div>
                    <div class="step-img">
                        <b-img
                            fluid
                            :src="require('@/assets/images/home/step-5.png')"
                            alt="contact-icon"
                        />
                        <p>Create Models and Scenarios</p>
                        <span>Create Models and Scenarios all the way from the start or start running adapting from one of our Industry Templates and demo data sets.</span>
                    </div>
                </slick>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import $ from "jquery";
import { BRow, BCol, BImg } from 'bootstrap-vue'
import Slick from 'vue-slick'
import 'slick-carousel/slick/slick.css'


export default {
    components: {
        BRow,
        BCol,
        BImg,
        Slick
    },
    data() {
        return {
            slickOptionsStep: {
                centerMode: true,
				dots: false,
				arrows: false,
				slidesToShow: 3,
				slidesToScroll: 1,
                responsive: [
                    {
                    breakpoint: 992,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            centerMode: false,
                        }
                    },
                ]
            },
        };
    },
    methods: {
		handleNextSlideStep() {
			this.$refs.slickAbout.next();
		},
		handlePrevSlideStep() {
			this.$refs.slickAbout.prev();
		},
	}
}
</script>