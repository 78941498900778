<template>
    <div class="our-benefits">
        <b-row class="m-0 p-0">
            <b-col
                class="benefits-text"
                offset-lg="2"
                lg="5"
                md="8"
            >
                <span class="top-title">Our benefits</span>
                <h4>Title about how most customers aren’t profitable</h4>
            </b-col>
            <b-col
                class="benefits-button"
                lg="3"
                md="4"
            >
                <b-button class="btn-benefits">
                    Example CTA
                    <feather-icon
                        icon="ArrowRightIcon"
                    />
                </b-button>
            </b-col>
        </b-row>
        <b-row class="m-0 p-0">
            <b-col
                class="benefits-cards"
                offset-lg="2"
                lg="8"
                md="12"
            >
                <div class="benefits-img">
                    <b-img
                        fluid
                        :src="require('@/assets/images/home/benefits-1.png')"
                        alt="contact-icon"
                    />
                    <p>Create Models and Scenarios</p>
                    <span>Create Models and Scenarios all the way from the start or start running adapting from one of our Industry Templates and demo data sets.</span>
                </div>
                <div class="benefits-img benefits-middle">
                    <b-img
                        fluid
                        :src="require('@/assets/images/home/benefits-2.png')"
                        alt="contact-icon"
                    />
                    <p>Create Models and Scenarios</p>
                    <span>Create Models and Scenarios all the way from the start or start running adapting from one of our Industry Templates and demo data sets.</span>
                </div>
                <div class="benefits-img">
                    <b-img
                        fluid
                        :src="require('@/assets/images/home/benefits-3.png')"
                        alt="contact-icon"
                    />
                    <p>Create Models and Scenarios</p>
                    <span>Create Models and Scenarios all the way from the start or start running adapting from one of our Industry Templates and demo data sets.</span>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { BRow, BCol, BImg, BButton } from 'bootstrap-vue'

export default {
    components: {
        BRow,
        BCol,
        BImg,
        BButton
    },
}
</script>